
import {Options, Vue} from 'vue-class-component';
import {
  URL_YANDEX_OAUTH2_LOGIN,
  URL_VK_OAUTH2_LOGIN,
  URL_LOGOUT,
  URL_DATA_PROCESSOR_CLIENTS_OUR_ACCOUNT_RELATIVE
} from '@/system/constants';
import {
  IClient,
  IOtherClient,
  ClientsService
} from '@/system/services/ClientsService';
import {EventsProcessor} from '@/system/common_code/EventsProcessor';

@Options({
  props: {
  },

  data: () => ({
    // m_queryProcessingSubscribers: []
  }),

  created () {
    document.title = `Профиль подписчика - Фото-Фитнес-Студия ArtPro`;
  },

  async mounted () {
    await this.showClientInformation ();
  },

  methods: {
    processQuery: async function () {
      const query = location.search.substr(1);
      if (query) {
        // ClientsService.logout ();
        // console.log (query);
        const [parameter, value] = query.split('=');
        if ((parameter == "provider") && (value == "Yandex")) {
          const hash = location.hash.substr (1);
          const [stringToken] = hash.split('&');
          const [, token] = stringToken.split('=');
          // console.log (token);
          const isLoggedIn = ClientsService.isLoggedIn ();
          if (! isLoggedIn) {
            await ClientsService.loginYandex (token);

          } else {
            await ClientsService.addYandexId (token);
          }

        } else if (parameter == "code") {
          console.log (value);
          const isLoggedIn = ClientsService.isLoggedIn ();
          if (! isLoggedIn) {
            await ClientsService.loginVK (value);

          } else {
            await ClientsService.addVKId (value);
          }

        } else if ((parameter == "logout") && (value == "true")) {
          ClientsService.logout ();

        }
      }
    },

    showLoginElements: function () {
      const divLogin = document.getElementById ("divLogin");
      const linkLoginViaYandex = document.getElementById ("linkLoginViaYandex");
      const linkLoginViaVK = document.getElementById ("linkLoginViaVK");
      if (divLogin && linkLoginViaYandex && linkLoginViaVK) {
        divLogin.style.display = "block";
        linkLoginViaYandex.setAttribute ("href", URL_YANDEX_OAUTH2_LOGIN);
        linkLoginViaVK.setAttribute ("href", URL_VK_OAUTH2_LOGIN);
      }
    },

    async showClientInformation () {
      if (this.$route.params.id) {
        await this.showOtherClientInformation ();

      } else {
        await this.processQuery ();
        EventsProcessor.triggerEvent ("eventProfileQueryProcessed");

        const isLoggedIn = ClientsService.isLoggedIn ();
        // console.log (`Is client logged in is : ${isLoggedIn}.`);
        console.log (`Token : ${ClientsService.getAuthorizationToken ()}.`);

        if (!isLoggedIn) {
          this.showLoginElements ();

        } else {
          await this.showOurInformation ();
        }
      }
    },

    showOurInformation: async function () {
      const client = await ClientsService.GetUs ();
      if (!client) {
        ClientsService.logout ();
        return;
      }

      this.showClientProperties (client);
      const linkLogout = document.getElementById ("linkLogout");
      const spanCountry = document.getElementById ("spanCountry");
      const edCountry = document.getElementById ("edCountry") as HTMLInputElement;
      const spanCity = document.getElementById ("spanCity");
      const edCity = document.getElementById ("edCity") as HTMLInputElement;
      const spanBiography = document.getElementById ("spanBiography");
      const textareaBiography = document.getElementById ("textareaBiography") as HTMLInputElement;
      const spanEMailPublic = document.getElementById ("spanEMailPublic");
      const edEMailPublic = document.getElementById ("edEMailPublic") as HTMLInputElement;
      const spanPhonePublic = document.getElementById ("spanPhonePublic");
      const edPhonePublic = document.getElementById ("edPhonePublic") as HTMLInputElement;
      const linkSite = document.getElementById ("linkSite");
      const edSite = document.getElementById ("edSite") as HTMLInputElement;
      const linkURLVK = document.getElementById ("linkURLVK");
      const edURLVK = document.getElementById ("edURLVK") as HTMLInputElement;
      const linkURLTelegramm = document.getElementById ("linkURLTelegramm");
      const edURLTelegramm = document.getElementById ("edURLTelegramm") as HTMLInputElement;
      if (linkLogout &&
        spanCountry && edCountry && spanCity && edCity && textareaBiography && spanBiography &&
        spanEMailPublic && edEMailPublic && spanPhonePublic && edPhonePublic &&
        linkSite && edSite && linkURLVK && edURLVK && linkURLTelegramm && edURLTelegramm
      ) {
        linkLogout.setAttribute ("href", URL_LOGOUT);
        linkLogout.style.display = "inline";

        spanCountry.style.display = "none";
        edCountry.value = client.country ? client.country : "";
        spanCity.style.display = "none";
        edCity.value = client.city ? client.city : "";
        spanBiography.style.display = "none";
        textareaBiography.value = client.biography ? client.biography : "";
        spanEMailPublic.style.display = "none";
        edEMailPublic.value = (client.contacts && client.contacts.emailPublic) ? client.contacts.emailPublic : "";
        spanPhonePublic.style.display = "none";
        edPhonePublic.value = (client.contacts && client.contacts.phonePublic) ? client.contacts.phonePublic : "";
        linkSite.style.display = "none";
        edSite.value = (client.contacts && client.contacts.site) ? client.contacts.site : "";
        linkURLVK.style.display = "none";
        edURLVK.value = (client.contacts && client.contacts.urlVK) ? client.contacts.urlVK : "";
        linkURLTelegramm.style.display = "none";
        edURLTelegramm.value = (client.contacts && client.contacts.urlTelegramm) ? client.contacts.urlTelegramm : "";
      }
    },

    showOtherClientInformation: async function () {
      const client = await ClientsService.GetClient (this.$route.params.id);
      if (!client) {
        return;
      }

      this.showClientProperties (client);
      const buttonDeleteOurAccount = document.getElementById ("buttonDeleteOurAccount");
      const linkLogout = document.getElementById ("linkLogout");
      const spanCountry = document.getElementById ("spanCountry");
      const edCountry = document.getElementById ("edCountry") as HTMLInputElement;
      const spanCity = document.getElementById ("spanCity");
      const edCity = document.getElementById ("edCity") as HTMLInputElement;
      const textareaBiography = document.getElementById ("textareaBiography") as HTMLInputElement;
      const spanBiography = document.getElementById ("spanBiography");
      const spanEMailPublic = document.getElementById ("spanEMailPublic");
      const edEMailPublic = document.getElementById ("edEMailPublic") as HTMLInputElement;
      const spanPhonePublic = document.getElementById ("spanPhonePublic");
      const edPhonePublic = document.getElementById ("edPhonePublic") as HTMLInputElement;
      const linkSite = document.getElementById ("linkSite");
      const spanSite = document.getElementById ("spanSite");
      const linkURLVK = document.getElementById ("linkURLVK");
      const spanURLVK = document.getElementById ("spanURLVK");
      const linkURLTelegramm = document.getElementById ("linkURLTelegramm");
      const spanURLTelegramm = document.getElementById ("spanURLTelegramm");
      const buttonUpdateOurInformation = document.getElementById ("buttonUpdateOurInformation");
      if (buttonDeleteOurAccount && linkLogout &&
        spanCountry && edCountry && spanCity && edCity && textareaBiography && spanBiography &&
        spanEMailPublic && edEMailPublic && spanPhonePublic && edPhonePublic &&
        linkSite && spanSite && linkURLVK && spanURLVK && linkURLTelegramm && spanURLTelegramm &&
        buttonUpdateOurInformation
      ) {
        buttonDeleteOurAccount.style.display = "none";
        linkLogout.style.display = "none";

        edCountry.style.display = "none";
        spanCountry.innerHTML = client.country ? client.country : "";
        edCity.style.display = "none";
        spanCity.innerHTML = client.city ? client.city : "";
        textareaBiography.style.display = "none";
        spanBiography.innerHTML = client.biography ? client.biography : "";
        edEMailPublic.style.display = "none";
        spanEMailPublic.innerHTML = (client.contacts && client.contacts.emailPublic) ? client.contacts.emailPublic : "";
        edPhonePublic.style.display = "none";
        spanPhonePublic.innerHTML = (client.contacts && client.contacts.phonePublic) ? client.contacts.phonePublic : "";
        spanSite.style.display = "none";
        if (client.contacts && client.contacts.site) {
          linkSite.setAttribute ("href", `https://${client.contacts.site}`);
          linkSite.innerHTML = client.contacts.site;
        }
        linkSite.style.display = (client.contacts && client.contacts.site) ? "inline" : "none";
        spanURLVK.style.display = "none";
        if (client.contacts && client.contacts.urlVK) {
          linkURLVK.setAttribute ("href", `https://vk.com/${client.contacts.urlVK}`);
          linkURLVK.innerHTML = client.contacts.urlVK;
        }
        linkURLVK.style.display = (client.contacts && client.contacts.urlVK) ? "inline" : "none";
        spanURLTelegramm.style.display = "none";
        if (client.contacts && client.contacts.urlTelegramm) {
          linkURLTelegramm.setAttribute ("href", `https://t.me/${client.contacts.urlTelegramm}`);
          linkURLTelegramm.innerHTML = client.contacts.urlTelegramm;
        }
        linkURLTelegramm.style.display = (client.contacts && client.contacts.site) ? "inline" : "none";

        buttonUpdateOurInformation.style.display = "none";
      }
    },

    // addQueryProcessingSubscriber: async function (inputProcessor: Function) {
    //   this.m_queryProcessingSubscribers.add (inputProcessor);
    // },

    buttonDeleteOurAccount_OnClick: function () {
      // console.log ("buttonDeleteOurAccount_OnClick () : Function has been called.");
      if (confirm ("Вы уверены, что хотите удалить аккаунт и всего его данные без возможности восстановления?")) {
        ClientsService.DeleteOurAccount ();
        ClientsService.logout ();
        window.location.href = "/profile/";

      } else {
        // Do nothing!

      }
    },

    async buttonUpdateOurInformation_OnClick () {
      const edCountry = document.getElementById ("edCountry") as HTMLInputElement;
      const edCity = document.getElementById ("edCity") as HTMLInputElement;
      const textareaBiography = document.getElementById ("textareaBiography") as HTMLInputElement;
      const edEMailPublic = document.getElementById ("edEMailPublic") as HTMLInputElement;
      const edPhonePublic = document.getElementById ("edPhonePublic") as HTMLInputElement;
      const edSite = document.getElementById ("edSite") as HTMLInputElement;
      const edURLVK = document.getElementById ("edURLVK") as HTMLInputElement;
      const edURLTelegramm = document.getElementById ("edURLTelegramm") as HTMLInputElement;
      if (edCountry && edCity && textareaBiography &&
        edEMailPublic && edPhonePublic && edSite && edURLVK && edURLTelegramm
      ) {
        await ClientsService.UpdateOurAccount (
          edCountry.value,
          edCity.value,
          textareaBiography.value,
          edEMailPublic.value,
          edPhonePublic.value,
          edSite.value,
          edURLVK.value,
          edURLTelegramm.value
        );
      }
    },

    showClientProperties (inputClient: IClient) {
      const divClientInformation = document.getElementById ("divClientInformation");
      const spanClientName = document.getElementById ("spanClientName");
      const spanClientSurname = document.getElementById ("spanClientSurname");
      const divClientGender = document.getElementById ("divClientGender");
      const spanClientDateBirth = document.getElementById ("spanClientDateBirth");
      const spanClientRegistrationDate = document.getElementById ("spanClientRegistrationDate");
      const spanClientUploadsAmount = document.getElementById ("spanClientUploadsAmount");
      const spanClientImagesAmount = document.getElementById ("spanClientImagesAmount");
      const spanClientMaxImages = document.getElementById ("spanClientMaxImages");
      const spanClientAccountStatus = document.getElementById ("spanClientAccountStatus");
      // const buttonDeleteOurAccount = document.getElementById ("buttonDeleteOurAccount");
      const divClientUploadsAmount = document.getElementById ("divClientUploadsAmount");
      const divClientMaxImages = document.getElementById ("divClientMaxImages");
      const spanClientRating = document.getElementById ("spanClientRating");
      if (divClientInformation && spanClientName && spanClientSurname &&
          divClientGender && spanClientDateBirth && spanClientRegistrationDate &&
          divClientUploadsAmount && spanClientUploadsAmount &&
          spanClientImagesAmount && divClientMaxImages && spanClientMaxImages &&
          spanClientRating &&
          spanClientAccountStatus
      ) {
        divClientInformation.style.display = "block";
        spanClientName.innerHTML = inputClient.name;
        spanClientSurname.innerHTML = inputClient.surname;
        divClientGender.innerHTML = ClientsService.getGenderName (inputClient.gender);
        spanClientDateBirth.innerHTML = inputClient.dateBirth.toString ();
        spanClientRegistrationDate.innerHTML = inputClient.dateCreation.toString ();
        divClientUploadsAmount.style.display = inputClient.limitUploadCurrent ? "block" : "none";
        if (inputClient.limitUploadCurrent) {
          spanClientUploadsAmount.innerHTML = `${inputClient.limitUploadCurrent}`;
        }
        divClientMaxImages.style.display = inputClient.maxImagesAmount ? "block" : "none";
        if (inputClient.maxImagesAmount) {
          spanClientMaxImages.innerHTML = `${inputClient.maxImagesAmount}`;
        }
        spanClientImagesAmount.innerHTML = `${inputClient.amountImages}`;
        spanClientRating.innerHTML = `${inputClient.rating}`;
        spanClientAccountStatus.innerHTML = ClientsService.getStatusName (inputClient.status);
      }
    }
  }

})
export default class ComponentProfile extends Vue {
  msg!: string
}
