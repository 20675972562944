export class EventsProcessor {
	static triggerEvent (inputName : string): void {
		const event = new Event (inputName);
		document.dispatchEvent (event);
    }

    static addEventSubscriber (inputName: string, inputProcessor: EventListenerObject): void {
	    if (document.addEventListener) {
			document.addEventListener (inputName, inputProcessor, false);

		} else {
			// document.attachEvent (inputName, inputProcessor);
		}
    }
}